.contact-reminder {
  padding-bottom: 30px;
}
.contact-heading {
  border-left: 3px solid $input-border;
  padding-left: 1.5em;
  .editor {
    @include fz(16px);
  }
}
.contact-form {
  background: $sect-bg;
  padding: 30px 0;
}
.contact-info {
  padding-top: 30px;
}
.contact-list {
  @include fz(16px);
  .item {
    margin-top: 2em;
    padding: 1px 0;
  }
  .title {
    color: $brand-primary;
    @include fz(20,18);
    font-weight: bold;
  }
  .info {
    color: $text-color3;
    margin-bottom: 1em;
    a {
      display: inline;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .map {
    height: 180px;
    border: 1px solid $border-color;
    position: relative;
    margin: 0 20px 20px 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
    }
    &:before {
      content: '';
      display: block;
      position: absolute;
      border: 1px solid $border-color;
      border-width: 0 1px 1px 0;
      top: 35px;
      left: 35px;
      right: -20px;
      bottom: -20px;
    }
  }
}

@media (min-width: $screen-xs-min) {

}
@media (min-width: $screen-sm-min) {
  .contact-heading {
    padding-left: 2em;
    .editor {
      @include fz(20px);
    }
  }
  .contact-form {
    padding: 50px 0;
  }
  .contact-info {
    padding-top: 60px;
  }
  .contact-list {
    @include fz(18px);
    margin: 0 -15px;
    @include clearfix;
    .item {
      float: left;
      width: 50%;
      padding: 1px 15px;
      margin-top: 50px;
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    .info {
      min-height: $line-height-base * 4 + 0em;
    }
  }
}
@media (min-width: $screen-md-min) {
  .contact-list {
    margin: 0 -25px;
    padding: 0 2em;
    .item {
      padding: 1px 25px;
    }
    .title {
      float: left;
      width: 7em;
    }
    .info {
      margin-left: 7.5em;
    }
    .map {
      clear: both;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .contact-list {
    margin: 0 -40px;
    .item {
      padding: 1px 40px;
    }
  }
}
