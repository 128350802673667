.bt {
  @include btn-reset;
  &:hover {
    text-decoration: none;
  }
  &-simple {
    border: 1px solid $input-border;
    padding: 0.5em 1em;
    min-width: 90px;
    background: #fff;
    &:hover {
      background: $brand-primary;
      color: #fff;
    }
  }
  &-primary {
    background: $brand-primary;
    color: #fff;
    border-radius: 5px;
    @include fz(20,16);
    padding: 0.3em 2em;
    .ic {
      font-size: 1.2em;
      margin: 0 0 0.05em 0.3em;
    }
    &:hover {
      color: #000;
      background: $brand-primary2;
    }
  }
  &-mix {
    background: #00a7ff;
    background: -moz-linear-gradient(left,  #00a7ff 0%, #ffd400 100%);
    background: -webkit-linear-gradient(left,  #00a7ff 0%,#ffd400 100%);
    background: linear-gradient(to right,  #00a7ff 0%,#ffd400 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a7ff', endColorstr='#ffd400',GradientType=1 );
    color: #fff;
    width: 204 / 18 + 0em;
    line-height: 46 / 18 + 0em;
    padding: 0;
    &:hover {
      background: #ffd400;
      background: -moz-linear-gradient(left,  #ffd400 0%, #00a7ff 100%);
      background: -webkit-linear-gradient(left,  #ffd400 0%,#00a7ff 100%);
      background: linear-gradient(to right,  #ffd400 0%,#00a7ff 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd400', endColorstr='#00a7ff',GradientType=1 );
    }
  }
}
.bt-box {
  clear: both;
  text-align: center;
  padding-top: 30px;
  margin: 0 auto;
  @include clearfix;
  &.with-pager {
    margin-top: 35px;
    border-top: 1px solid #ccc;
    text-align: right;
    .pager {
      padding-top: 1px;
      padding-right: 1px;
      overflow: hidden;
      float: left;
      .bt {
        float: left;
        margin-right: -1px;
      }
    }
  }
}
@media (min-width: $screen-sm-min) {
  .bt {
    &-simple {
      min-width: 150px;
      @include fz(16px);
    }
  }
  .bt-box {
    padding-top: 56px;
    &.with-pager {
      padding-top: 48px;
    }
  }
}
@media (min-width: $screen-md-min) {
  .bt {
    &-simple {
      min-width: 203px;
    }
  }
  .bt-box {
    &.with-pager {
      .pager {
        float: none;
        display: inline-block;
        vertical-align: middle;
      }
      .back {
        margin-left: 32px;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
}
