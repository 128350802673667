.footer {
  background: $brand-primary3;
  color: #fff;
  padding: 30px 0 20px;
  text-align: center;
  .bt.gotop {
    position: fixed;
    z-index: 100;
    right: 5px;
    bottom: 5px;
    text-align: center;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    background: rgba($brand-primary,0.8);
    width: 40px;
    height: 40px;
    padding-top: 0.4em;
    border-radius: 50%;
    .header-fixed & {
      opacity: 1;
      pointer-events: visible;
    }
    &:hover {
      background: rgba($brand-primary2,0.8);
    }
    .ic {
      vertical-align: top;
      width: 30px;
      line-height: 30px;
      @include fz(20px);
    }
    .txt {
      display: none;
    }
  }
  &-contact {
    border-bottom: 1px solid #777;
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;
    .title {
      @include fz(18,16);
      margin-bottom: 1em;
      strong {
        display: block;
        font-size: 1.5em;
        font-weight: 500;
      }
    }
    .bt {
      font-weight: bold;
      background: $brand-primary2;
      color: $brand-primary;
      width: 160 / 18 + 0em;
      line-height: (51 / 18);
      border: 2px solid $brand-primary2;
      border-radius: 30px;
      &:hover {
        transform: scale(0.9);
      }
    }
  }
  &-siteinfo {
    display: none;
  }
  &-follow {
    @include fz(20px);
    margin-bottom: 0.5em;
    li {
      margin-right: 0.5em;
      a {
        width: 25px;
        line-height: 25px;
        text-align: center;
        &:hover {
          color: $brand-primary2;
        }
      }
    }
  }
  &-copyright {
    @include fz(12px);
    .author {
      display: block;
      color: rgba(#fff,0.5);
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .footer {
    padding: 55px 0 25px;
    .bt.gotop {
      bottom: 30px;
    }
    &-contact {
      position: relative;
      text-align: left;
      padding: 0 10px;
      margin-bottom: 35px;
      .title {
        margin-bottom: 0;
        padding: 1.1em 0;
        strong {
          line-height: 1;
          font-size: 2em;
          letter-spacing: 0.05em;
          margin-bottom: 0.15em;
        }
      }
      .bt {
        @include fz(18px);
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -28px;
      }
    }
    &-siteinfo {
      display: block;
      border-bottom: 1px solid #777;
      text-align: left;
      padding: 0 20px;
      margin-bottom: 35px;
      @include clearfix;
      .item {
        float: left;
        width: 50%;
        padding: 0 15px 50px;
        &:nth-child(2n+1) {
          clear: left;
        }
      }
      .title {
        @include fz(18,16);
        font-weight: bold;
      }
      .info {
        color: rgba(#fff,0.5);
        li {
          &:last-child {
            color: #fff;
            font-family: $font-family-sans-serif2;
          }
          .ic {
            margin-right: 0.2em;
            margin-bottom: 0.1em;
          }
          a {
            &:hover {
              color: $brand-primary2;
            }
          }
        }
      }
    }
    &-follow,
    &-copyright,
    &-copyright .author {
      display: inline-block;
      vertical-align: middle;
    }
    &-copyright {
      .author {
        margin-left: 1em;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .footer {
    .container {
      max-width: 1290px;
    }
    &-contact {
      .title {
        @include fz(18px);
      }
    }
    &-siteinfo {
      @include fz(16px);
    }
  }
}
@media (min-width: $screen-lg-min) {
  .footer {
    &-siteinfo {
      .item {
        width: 25%;
        &:nth-child(2n+1) {
          clear: none;
        }
        &:nth-child(4n+1) {
          clear: left;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .footer {
    position: relative;
    .bt.gotop {
      width: 60px;
      height: 60px;
      padding-top: 0.2em;
      line-height: 20px;
      .ic {
      }
      .txt {
        display: block;
        font-family: $font-family-sans-serif2;
      }
    }
  }
}
@media (min-width: $screen-xxl-min) {
  .footer {
    .bt.gotop {
      right: percentage(110 / 1920);
    }
  }
}
