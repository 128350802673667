.side {
  &-sect {
    margin-bottom: 40px;
  }
  &-title {
    @include fz(24px);
    padding: 0 16px 20px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    position: relative;
    &:after {
      content: '＋';
      position: absolute;
      right: 16px;
      top: 6px;
      color: #fff;
      background: $brand-primary;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      line-height: 1;
      text-align: center;
    }
    &.active {
      &:after {
        content: '－';
      }
      + .side-nav {
        display: block;
      }
    }
    &:hover {
      &:after {
        background: $brand-primary2;
        color: $brand-primary;
      }
    }
  }
  &-nav {
    display: none;
    line-height: 1.3;
    li {
      a {
        position: relative;
        padding: 12px 32px 12px 16px;
        border-bottom: 1px solid #ccc;
        &:after {
          content: '';
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 4px 0 4px 6px;
          opacity: 0;
        }
      }
    }
    li.current > a {
      &:after {
        opacity: 1;
      }
    }
    > li {
      > a {
        @include fz(16px);
        &:after {
          border-color: transparent transparent transparent #fff;
        }
      }
    }
    > li > a:hover,
    > li.current > a {
      color: #fff;
      background: $brand-primary;
    }
    > li.has-child.current {
      > a {
        &:after {
          border-width: 6px 4px 0 4px;
          border-color: #fff transparent transparent transparent;
        }
      }
    }
    ul {
      > li {
        > a {
          color: $text-color2;
          padding-left: 45px;
          &:before {
            content: '';
            display: block;
            background: $text-color2;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 28px;
            top: 18px;
          }
          &:after {
            border-color: transparent transparent transparent $brand-primary;
          }
        }
      }
      > li > a:hover,
      > li.current > a {
        color: $brand-primary;
        &:before {
          background: $brand-primary;
        }
      }
    }
  }
}
.has-side {
  .container {
    max-width: 1640px;
  }
}

@media (min-width: $screen-sm-min) {
  .side {
    display: block;
    float: left;
    width: 210px;
    &-title {
      cursor: text;
      &:after {
        display: none;
      }
    }
    &-nav {
      display: block;
    }
  }
  .has-side {
    .content {
      float: right;
      width: calc(100% - 250px);
    }
  }
}
@media (min-width: $screen-md-min) {
  .side {
    display: block;
    float: left;
    width: 240px;
  }
  .has-side {
    .content {
      float: right;
      width: calc(100% - 308px);
    }
  }
}
@media (min-width: $screen-lg-min) {

}
