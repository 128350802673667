@keyframes shipMove {
  0% { right: 100%; }
  100% { right: percentage(263 / 768); }
}
@keyframes shipMove2 {
  0% { right: 100%; }
  100% { right: 20%; }
}
@keyframes shipMove3 {
  0% { right: 100%; margin-right: 0; }
  100% { right: 50%; margin-right: -300px; }
}
@keyframes fishMove {
  0% { transform: rotate(0deg); }
  45% { transform: rotate(-120deg); }
  70% { transform: rotate(-240deg); }
  100% { transform: rotate(-360deg); }
}
.anime {
  visibility: hidden;
  &.anime-run {
    visibility: visible;
  }
}
.index {
  &-banner {
    .ship.anime-run {
      animation: shipMove 5s;
      animation-fill-mode: forwards;
    }
  }
}

@media screen and (min-width: $screen-sm-min)  {
  .index {
    &-banner {
      .ship.anime-run {
        animation-name: shipMove2;
      }
    }
    &-about-txt::after {
      animation: fishMove 6s infinite;
      transform-origin: 125% 150%;
    }
  }
}
@media screen and (min-width: 1440px)  {
  .index {
    &-banner {
      .ship.anime-run {
        animation-name: shipMove3;
      }
    }
  }
}
