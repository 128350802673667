.news-list {
  .box {
    border: 1px solid $border-color2;
    padding: 18px;
    margin: 0 auto 30px;
    color: $text-color3;
    &:hover {
      background: $border-color2;
    }
    .pic {
      background: 50% 50% no-repeat;
      background-size: cover;
      max-width: 240px;
      margin-bottom: 12px;
      &:before {
        content: '';
        display: block;
        padding-top: percentage(150 / 240);
      }
    }
    .category,
    .date {
      display: inline-block;
      vertical-align: middle;
      line-height: 20px;
    }
    .category {
      color: #000;
      background: $brand-primary2;
      @include fz(12px);
      padding: 0 0.5em;
      margin-right: 0.8em;
    }
    .date {
      font-family: $font-family-sans-serif2;
    }
    .title {
      @include multi-line(2);
      color: #000;
      @include fz(18px);
      font-weight: bold;
      line-height: (28 / 18);
      margin: 0.5em 0;
    }
    .desc {
      @include multi-line(2);
    }
  }
}
.news-detail {
  .category,
  .date {
    display: inline-block;
    vertical-align: middle;
    line-height: 20px;
  }
  .category {
    color: #000;
    background: $brand-primary2;
    @include fz(12px);
    padding: 0 0.5em;
    margin-right: 0.8em;
  }
  .date {
    font-family: $font-family-sans-serif2;
  }
  .title {
    color: #000;
    @include fz(18px);
    font-weight: bold;
    line-height: (28 / 18);
    margin: 0.5em 0 0;
    padding-bottom: 0.8em;
    border-bottom: 1px solid $border-color2;
  }
  .editor {
    padding: 1.5em 0;
  }
  &-pics {
    margin: 0 -8px;
    @include clearfix;
    .pic {
      float: left;
      padding: 8px;
      width: 50%;
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
  .bt-box {
    border-top: 1px solid $border-color2;
    margin-top: 2em;
  }
}

@media (min-width: $screen-xs-min) {
  .news-list {
    .box {
      padding: 28px 18px;
      overflow: hidden;
      .pic {
        float: left;
        width: percentage(240 / 594);
        margin: 0 20px 0 0;
      }
      .txt {
        overflow: hidden;
      }
    }
  }
}
@media (min-width: $screen-sm-min) {

}
@media (min-width: $screen-md-min) {
  .news-list {
    .box {
      margin-bottom: 45px;
    }
  }
  .news-detail {
    &-pics {
      .pic {
        width: 33.33%;
        &:nth-child(2n+1) {
          clear: none;
        }
        &:nth-child(3n+1) {
          clear: left;
        }
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .news-list {
    margin: 0 -15px;
    @include clearfix;
    .item {
      float: left;
      width: 50%;
      padding: 0 15px;
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
  .news-detail {
    &-pics {
      max-width: 1126px;
      .pic {
        width: 20%;
        &:nth-child(3n+1) {
          clear: none;
        }
        &:nth-child(5n+1) {
          clear: left;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {

}
