.case-list {
  margin: 0 -7px;
  @include clearfix;
  &-intro {
    border-left: 3px solid $input-border;
    padding-left: 1.5em;
    margin-bottom: 30px;
    .content-title {
      margin-bottom: 0;
    }
    .slogan {
      @include fz(16px);
    }
  }
  .item {
    float: left;
    width: 50%;
    padding: 0 7px;
    &:nth-child(2n+1) {
      clear: left;
    }
  }
  .box {
    text-align: center;
    max-width: 450px;
    margin: 0 auto 2em;
    .pic {
      border: 1px solid $border-color;
      background: rgba(#000,0.05) 50% 50% no-repeat;
      background-size: contain;
      margin-bottom: 1em;
      &:before {
        content: '';
        display: block;
        padding-top: percentage(300 / 450);
      }
    }
    .title {
      line-height: 1.3;
    }
  }
  .box:hover {
    .pic {
      border-color: $brand-primary;
    }
    .title {
      color: $brand-primary;
    }
  }
}
.case-detail {
  .title {
    color: $brand-primary;
    @include fz(18px);
    font-weight: bold;
    margin: 0 0 20px;
  }
  .logo {
    border-bottom: 1px solid $border-color;
    text-align: center;
  }
  .editor {
    padding: 25px 0 1px;
    @include fz(16px);
    line-height: 1.95;
  }
  &-layout1 {
    margin-bottom: 2.5em;
    .txt {
      margin-bottom: 1.5em;
    }
  }
}

@media (min-width: $screen-xs-min) {

}
@media (min-width: $screen-sm-min) {
  .case-list {
    margin: 0 -10px;
    &-intro {
      padding-left: 2.5em;
      margin-left: 1.2em;
      margin-bottom: 60px;
      .slogan {
        @include fz(20px);
      }
    }
    .item {
      width: 33.33%;
      padding: 0 10px;
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
    .box {
      @include fz(16px);
      margin-bottom: 60px;
    }
  }
  .case-detail {
    .title {
      @include fz(24px);
    }
    .editor {
      padding: 40px 25px;
    }
    &-layout1 {
      @include clearfix;
      .txt {
        float: left;
        width: percentage(770 / 1220);
      }
      .pic {
        float: right;
        width: percentage(394 / 1220);
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .case-list {
    .item {
      width: 25%;
      &:nth-child(3n+1) {
        clear: none;
      }
      &:nth-child(4n+1) {
        clear: left;
      }
    }
    .box {
      margin-bottom: 80px;
    }
  }
  .case-detail {
    .title {
      @include fz(30px);
    }
  }
}
@media (min-width: $screen-lg-min) {

}
