.support-sect1 {
  padding-bottom: 30px;
  .content-title2 {
    margin-bottom: 1.2em;
  }
}
.support-sect2 {
  background: $sect-bg;
  padding: 30px 0;
}
.support-sect3 {
  padding: 30px 0 0;
  .content-title2 {
    text-align: center;
    margin-bottom: 1.2em;
  }
}
.support-step {
  @include list-reset;
  text-align: center;
  @include fz(18,16);
  .item {
    position: relative;
    &:after {
      content: '>';
      color: $brand-primary;
      @include fz(48,18);
      width: 48px;
      line-height: 48px;
      font-weight: bold;
      margin-left: -20px;
      transform: rotate(90deg);
      position: absolute;
      bottom: -48px;
      left: 50%;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
  .box {
    border: 3px solid $brand-primary;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    margin: 0 auto 3em;
    padding-top: 40px;
    .pic {
      width: percentage(144 / 280);
      margin: 0 auto;
    }
  }
}

@media (min-width: $screen-xs-min) {

}
@media (min-width: $screen-sm-min) {
  .support-sect1,
  .support-sect2,
  .support-sect3 {
    @include fz(16px);
    line-height: (30 / 16);
    .content-title2 {
      @include fz(20,16);
    }
  }
  .support-sect1 {
    padding-bottom: 45px;
  }
  .support-intro {
    position: relative;
    padding-right: 240px;
    .bt-primary {
      position: absolute;
      right: 0;
      bottom: 30%;
      transform: translateY(50%);
    }
  }
  .support-sect2 {
    padding: percentage(60 / 1250) 0;
  }
  .support-sect3 {
    padding-top: percentage(75 / 1250);
    .content-title2 {
      text-align: center;
      margin-bottom: 1.2em;
    }
  }
  .support-step {
    margin: 0 auto;
    max-width: 760px;
    .item {
      display: inline-block;
      vertical-align: top;
      &:nth-child(2n+1) {
        clear: left;
      }
      &:after {
        transform: none;
        position: static;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        left: 0;
        right: -48px;
        top: 0;
        bottom: 0;
        margin: 0;
      }
    }
    .box {
      display: inline-block;
      vertical-align: middle;
      margin: 30px 0;
      width: 260px;
      height: 260px;
      padding-top: 40px;
    }
  }
}
@media (min-width: $screen-md-min) {

}
@media (min-width: $screen-lg-min) {
  .support-sect2 {
    padding: 60px 0;
  }
  .support-sect3 {
    padding-top: 75px;
  }
}
@media (min-width: 1290px) {
  .support-step {
    max-width: none;
  }
}
