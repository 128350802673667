.about-heading {
  border-left: 3px solid $input-border;
  padding-left: 1.5em;
  margin-bottom: 80px;
  .content-title {
    margin-bottom: 0;
  }
  .slogan {
    @include fz(16px);
  }
}
.about-sect1 {
  position: relative;
  padding-bottom: 10%;
  .deco1,
  .deco2 {
    position: absolute;
    z-index: -1;
    pointer-events: none;
  }
  .deco1 {
    left: 0;
    bottom: 0;
    width: percentage(335 / 1280);
  }
  .deco2 {
    right: 0;
    bottom: 20%;
    width: percentage(316 / 1280);
  }
}
.about-intro {
  .pic {
    position: relative;
    max-width: 572px;
    margin-bottom: 1.5em;
    .deco {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(percentage(186 / 282), percentage(-90 / 277));
      width: percentage(282 / 572);
    }
    img {
      position: relative;
    }
  }
}
.about-sect2 {
  background: $sect-bg;
  padding-bottom: 25px;
}
.about-service {
  .pic {
    transform: translateY(-25%);
    margin-left: 25%;
  }
  .txt {
    .primary {
      color: $brand-primary;
    }
  }
}
.about-sect3 {
  padding-top: 40px;
}
.about-video {
  .item {
    margin-top: 2em;
  }
  .title {
    margin-top: 0.7em;
  }
}

@media (min-width: $screen-xs-min) {

}
@media (min-width: $screen-sm-min) {
  .about-heading {
    padding-left: 2.5em;
    margin-left: 1.2em;
    margin-bottom: 60px;
    .slogan {
      @include fz(20px);
    }
  }
  .about-intro,
  .about-service,
  .about-sect3 {
    @include fz(16px);
    line-height: (30 / 16);
    .content-title2 {
      @include fz(20,16);
      margin-bottom: 1.2em;
    }
  }
  .about-sect1 {
    padding-bottom: 15%;
  }
  .about-sect3 {
    padding-top: 60px;
  }
  .about-intro {
    padding-top: 5%;
    margin: 0 1.5em;
    .pic {
      width: 60%;
      margin-left: 2%;
    }
    .txt1,
    .txt2 {
      max-width: 80%;
      margin: 0 auto;
    }
  }
  .about-service {
    .txt {
      max-width: 80%;
      margin: 0 auto;
    }
  }
  .about-video {
    margin: 0 -10px;
    @include clearfix;
    .item {
      float: left;
      width: 50%;
      padding: 0 10px;
      &:nth-child(2n+1) {
        clear: left;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .about-sect1 {
    padding-bottom: 5%;
  }
  .about-intro {
    .pic,
    .txt2 {
      float: left;
      width: percentage(572 / 1250);
      margin: 0;
    }
    .txt1 {
      float: right;
      width: percentage(628 / 1250);
      position: relative;
      padding-top: 12%;
    }
    .txt2 {
      clear: both;
    }
  }
  .about-sect2 {
    padding: 5% 0;
  }
  .about-service {
    position: relative;
    .pic {
      position: absolute;
      top: 0;
      right: 0;
      width: percentage(628 / 1250);
      margin-left: 0;
      transform: translateY(percentage(-220 / 328));
      img {
        margin: 0;
      }
    }
    .txt {
      max-width: none;
      margin: 0;
      &:before {
        content: '';
        display: block;
        float: right;
        width: percentage(628 / 1250);
        height: 8em;
        margin-left: 2.5em;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .about-sect1 {
    padding-bottom: 40px;
    .deco1 {
      width: percentage(335 / 1920);
    }
    .deco2 {
      width: percentage(316 / 1920);
    }
  }
  .about-video {
    margin: 0 -15px;
    .item {
      padding: 0 15px;
    }
  }
}
@media (min-width: $screen-xl-min) {
  .about-video {
    margin: 0 -25px;
    .item {
      padding: 0 25px;
    }
  }
}
