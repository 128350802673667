.pagination {
  @include list-reset;
  display: inline-block;
  font-family: $font-family-sans-serif2;
  &-nav {
    text-align: center;
  }
  li {
    float: left;
    margin-left: -1px;
    a {
      display: block;
      color: $text-color2;
      border: 1px solid $input-border;
      min-width: 32px;
      height: 32px;
      line-height: 30px;
      padding: 0 5px;
      transition: 0.3s;
    }
    &:first-child {
      a {
        border-radius: 5px 0 0 5px;
      }
    }
    &:last-child {
      a {
        border-radius: 0 5px 5px 0;
      }
    }
  }
  li.current a,
  li a:hover {
    color: $brand-primary4;
    text-decoration: none;
  }
}

@media (min-width: $screen-xs-min) {
  .pagination {
    &-nav {
      padding-top: 1.5em;
    }
  }
}
@media (min-width: $screen-sm-min) {
  .pagination {
    @include fz(16px);
    li {
      a {
        min-width: 40px;
        height: 40px;
        line-height: 38px;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .pagination {
    @include fz(18px);
    li {
      a {
        min-width: 50px;
        height: 50px;
        line-height: 48px;
      }
    }
  }
}
