.header {
  position: fixed;
  z-index: 150;
  top: 0;
  left: 0;
  right: 0;
  transition: 0.3s;
  font-family: $font-family-sans-serif2;
  background: #fff;
  box-shadow: 0 0 10px rgba(#000,0.2);
  .site-title {
    font-size: 1em;
    margin: 0;
    padding: 14px 0 11px;
    .logo {
      display: inline-block;
      vertical-align: top;
      @include fz(15px);
      font-weight: bold;
      &:before {
        content: '';
        display: inline-block;
        vertical-align: text-bottom;
        background: url(../img/logo.svg) 50% 50% no-repeat;
        background-size: contain;
        width: 406 / 106 * 20 + 0px;
        height: 20px;
        margin-right: 0.3em;
      }
    }
  }
  .language {
    position: absolute;
    z-index: 10;
    right: 55px;
    top: 5px;
    &-toggle {
      font-size: 1.3em;
      width: 40px;
      height: 40px;
      .ic {
        margin-bottom: 0.1em;
      }
      .txt {
        display: none;
      }
    }
    .list {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      width: 100px;
      margin-left: -50px;
      padding-top: 16px;
      text-align: center;
      &:before {
        content: '';
        display: block;
        left: 50%;
        top: 0;
        width: 0;
        height: 0;
        border: 8px solid;
        border-color: transparent transparent #fff transparent;
        position: absolute;
        margin-left: -8px;
      }
      .language-open & {
        display: block;
      }
      li {
        background: #fff;
        + li {
          border-top: 1px solid $brand-primary;
        }
        a {
          padding: 0.3em 1em;
        }
      }
      li a:hover,
      li.current a {
        color: #fff;
        background: $brand-primary;
      }
    }
  }
}
.nav {
  position: fixed;
  z-index: 150;
  top: 50px;
  left: -375px;
  width: 375px;
  bottom: 0;
  overflow: hidden;
  pointer-events: visible;
  transition: 0.3s;
  &-open {
    overflow: hidden;
  }
  .nav-open & {
    width: 100%;
    left: 0;
  }
  &-toggle {
    position: absolute;
    z-index: 160;
    top: 5px;
    right: 14px;
    background: none;
    width: 40px;
    height: 40px;
    .nav-open & {
      position: fixed;
    }
    .ic {
      &-bar {
        background: $brand-primary;
        width: 24px;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1px;
        margin-left: -12px;
        transform-origin: center;
        transition: 0.3s;
        &:nth-of-type(1) {
          transform: translateY(-6px);
          .nav-open & {
            transform: rotate(45deg);
          }
        }
        &:nth-of-type(2) {
          transform: translateY(6px);
          .nav-open & {
            transform: rotate(-45deg);
          }
        }
        &:nth-of-type(3) {
          .nav-open & {
            display: none;
          }
        }
      }
    }
  }
  &-inner {
    width: 100%;
    color: #fff;
    background: $brand-primary;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .menu {
    @include fz(18px);
    border-bottom: 1px solid darken($brand-primary,5%);
    a {
      position: relative;
      padding: 0.5em 30px;
    }
    li.has-child {
      > a {
        &:after {
          content: '＋';
          position: absolute;
          right: 30px;
          top: 0.5em;
        }
      }
      &.open {
        > a {
          &:after {
            content: '－';
          }
        }
      }
    }
    li.open {
      > ul {
        display: block;
      }
    }
    > li {
      border-bottom: 1px solid lighten($brand-primary,5%);
      border-top: 1px solid darken($brand-primary,5%);
      > a {
        font-weight: bold;
        padding: 1em 30px;
      }
      ul {
        display: none;
        padding: 0.5em 0 0.5em 0.5em;
        background: darken($brand-primary,5%);
        font-size: 0.9em;
        > li > a:hover,
        > li.open > a,
        > li.current > a {
          color: #fff2b1;
        }
        ul {
          color: rgba(#fff,0.6);
          padding: 0 0 0 1.2em;
          a {
            padding: 0.3em 30px 0.3em 45px;
            &:before {
              content: '-';
              position: absolute;
              left: 30px;
            }
          }
          > li > a:hover,
          > li.open > a,
          > li.current > a {
            color: #fff;
          }
        }
      }
    }
    > li.has-child {
      > a {
        &:after {
          top: 1em;
        }
      }
    }
    > li > a:hover,
    > li.open > a,
    > li.current > a {
      color: $brand-primary2;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .header {
    .site-title {
      .logo {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .nav {
    .menu {
    }
  }
}
@media (min-width: $screen-md-min) {
  .header {
    .site-title {
      padding-top: 28px;
      float: left;
    }
    .language {
      right: 35px;
      top: 20px;
      z-index: 200;
      .list {
        padding-top: 0;
        box-shadow: 0 -1px 20px rgba(#000,0.15);
        &:before {
          top: -16px;
        }
        li {
        }
      }
    }
  }
  .nav {
    position: static;
    overflow: visible;
    margin-left: 230px;
    &-open {
      overflow: auto;
    }
    &,
    .nav-open & {
      width: auto;
      left: 0;
    }
    &-toggle {
      display: none;
    }
    &-inner {
      overflow: visible;
      position: static;
      background: none;
      color: $text-color;
    }
    .menu {
      @include fz(16px);
      border: 0;
      li {
        border: 0;
        display: inline-block;
        vertical-align: middle;
      }
      li.has-child {
        > a {
          &:after {
            display: none;
          }
        }
      }
      > li {
        > a {
          padding: 1.4em 0.8em;
          height: 80px;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
        > ul {
          &:before {
            content: '';
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            right: 0;
            height: 45px;
            pointer-events: none;
            box-shadow: inset 0 5px 10px -5px rgba(#000,0.2);
          }
        }
        ul {
          font-size: 1em;
          position: fixed;
          z-index: 100;
          top: 80px;
          left: 0;
          right: 0;
          background: #0069a0;
          background: -moz-linear-gradient(left,  #0069a0 0%, #feda26 100%);
          background: -webkit-linear-gradient(left,  #0069a0 0%,#feda26 100%);
          background: linear-gradient(to right,  #0069a0 0%,#feda26 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0069a0', endColorstr='#feda26',GradientType=1 );
          color: #fff;
          text-align: center;
          padding: 0;
          a {
            height: 45px;
            padding: 0.6em 2em;
          }
          > li > a:hover,
          > li.open > a,
          > li.current > a {
            color: $brand-primary;
            background: #fff;
          }
          ul {
            @include fz(14,16);
            background: #fff;
            top: 125px;
            color: $text-color2;
            padding: 10px 0;
            li {
              + li {
                &:before {
                  content: '/';
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 0.2em;
                }
              }
            }
            a {
              display: inline-block;
              vertical-align: middle;
              padding: 0.5em;
              height: auto;
              &:before {
                display: none;
              }
            }
            > li > a:hover,
            > li.open > a,
            > li.current > a {
              color: $brand-primary;
            }
          }
        }
      }
      > li > a:hover,
      > li.open > a,
      > li.current > a {
        color: $brand-primary;
        border-bottom-color: $brand-primary;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .header {
    .container {
      max-width: 1400px;
    }
    .site-title {
      padding-top: 23px;
      .logo {
        @include fz(18px);
        &:before {
          width: 406 / 106 * 26 + 0px;
          height: 26px;
        }
      }
    }
    .language {
      &-toggle {
        width: auto;
        &:after {
          content: '';
          display: inline-block;
          vertical-align: middle;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: $text-color transparent transparent transparent;
          margin-left: 0.4em;
        }
        .txt {
          display: inline-block;
          font-size: 0.8em;
          margin-left: 0.5em;
        }
      }
    }
  }
  .nav {
    margin-left: 250px;
    margin-right: 80px;
    .menu {
      text-align: center;
      > li {
        padding: 0 0.5em;
        > a {
          padding-left: 1em;
          padding-right: 1em;
        }
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .header {
  }
}
