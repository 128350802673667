.product-list {
  &-intro {
    margin-bottom: 60px;
    .content-title {
      margin-bottom: 1em;
    }
    .editor {
      @include fz(16px);
      line-height: 2;
    }
  }
  .box {
    max-width: 300px;
    margin: 0 auto 2em;
    text-align: center;
    .pic {
      background: rgba(#000,0.05) 50% 50% no-repeat;
      background-size: contain;
      margin-bottom: 1em;
      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }
    }
    .title {
      @include fz(20px);
      font-family: $font-family-sans-serif2;
    }
    .desc {
      color: $text-color3;
      @include fz(16px);
    }
  }
  .box:hover {
    .pic {
      background-color: rgba($brand-primary,0.1);
    }
    .title {
      color: $brand-primary;
    }
  }
}
.product-detail {
  &-head {
    text-align: center;
    padding-bottom: 1em;
  }
  &-title {
    color: $brand-primary;
    @include fz(30px);
    font-weight: 400;
    font-family: $font-family-sans-serif2;
    line-height: 1.2;
    margin: 0 0 20px;
    small {
      display: inline-block;
      @include fz(40,50);
      color: $text-color3;
    }
  }
  &-nav {
    .product-nav-fixed & {
      position: fixed;
      z-index: 100;
      top: 50px;
      left: 0;
      right: 0;
      padding: 15px 15px 10px;
      background: #fff;
      border: 1px solid #ddd;
      border-width: 1px 0;
    }
    li {
      a {
        width: 130 / 18 + 0em;
        line-height: 40 / 18 + 0em;
        border-radius: 20px;
        margin-bottom: 0.5em;
      }
    }
    li a:hover,
    li.current a {
      background: #00a7ff;
      background: -moz-linear-gradient(left,  #00a7ff 0%, #ffd400 100%);
      background: -webkit-linear-gradient(left,  #00a7ff 0%,#ffd400 100%);
      background: linear-gradient(to right,  #00a7ff 0%,#ffd400 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a7ff', endColorstr='#ffd400',GradientType=1 );
      color: #fff;
    }
  }
  &-sect {
    text-align: center;
    background: 50% 50% no-repeat;
    background-size: cover;
    padding: 2em 0;
    &.bg1 {
      background-image: url(../img/product_detail_bg1.jpg);
      color: #fff;
    }
    &.bg2 {
      background-image: url(../img/product_detail_bg2.jpg);
      color: #fff;
    }
    &.bg3 {
      background-image: url(../img/product_detail_bg3.jpg);
      color: #fff;
    }
    &.bg4 {
      background-image: url(../img/product_detail_bg4.jpg);
    }
    .content {
      @include fz(16px);
      text-shadow: 0 0 15px rgba(#000,0.3);
      .pic {
        margin-bottom: 1em;
      }
      .title {
        @include fz(40,30);
        font-weight: 400;
        line-height: 1.4;
        margin: 0 0 0.5em;
      }
      .desc {
        margin: 0;
      }
      .bt-box {
        padding-top: 1.5em;
      }
      .bt-mix {
        @include fz(18,30);
      }
    }
    &.spec {
      text-align: left;
      .spec-title {
        @include fz(20px);
        font-weight: 400;
        color: $brand-primary;
        margin-bottom: 1em;
      }
      .editor {
        @include fz(16px);
        line-height: 1.95;
      }
    }
    &.download {
      background: #eee;
      text-align: left;
      .download-title {
        @include fz(20px);
      }
    }
  }
  &-spec {
    .col {
      margin-bottom: 2em;
    }
  }
  .file-list {
    .box {
      @include fz(16px);
      margin-top: 20px;
      margin-right: 35px;
      .icon {
        margin-bottom: 0.5em;
      }
    }
  }
  &-pager {
    .bt-box.with-pager {
      border-top: 0;
      margin-top: 0;
    }
  }
}

@media (min-width: $screen-xs-min) {
  .product-list {
    @include clearfix;
    margin: 0 -15px;
    .item {
      float: left;
      width: 50%;
      padding: 0 15px;
      &:nth-child(2n+1) {
        clear: left;
      }
    }
    .box {
      margin-bottom: 3.5em;
    }
  }
}
@media (min-width: $screen-sm-min) {
  .product-detail {
    &-sect {
      padding: 3.5em 0;
      .content {
        @include fz(24px);
        .desc {
          line-height: 1.5;
        }
      }
      &.spec {
        .spec-title {
          @include fz(24px);
        }
      }
      &.download {
        padding-top: 2em;
        .download-title {
          @include fz(24px);
          margin-bottom: 0.5em;
        }
      }
      &.spec {
        padding: 3em 0;
      }
    }
    &-features2 {
      padding: 0 25px 40px;
      margin: 0 -25px 45px;
    }
  }
}
@media (min-width: $screen-md-min) {
  .product-list {
    &-intro {
      border-left: 3px solid $input-border;
      padding-left: 2.8em;
      margin-bottom: 80px;
    }
    .item {
      width: 33.33%;
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
  }
  .product-detail {
    &-head {
      @include clearfix;
      padding-bottom: 3em;
    }
    &-title {
      float: left;
      @include fz(40px);
    }
    &-nav {
      @include fz(16px);
      padding-top: 10px;
      &-holder {
        float: right;
      }
      .product-nav-fixed & {
        top: 80px;
      }
    }
    &-sect {
      padding: 4em 0;
      .content {
        @include fz(30px);
      }
      &.spec {
        .spec-title {
          @include fz(30px);
        }
      }
      &.download {
        .download-title {
          @include fz(30px);
        }
      }
    }
    &-spec {
      .row {
        @include clearfix;
        margin: 0 -15px;
      }
      .col {
        float: left;
        padding: 0 15px;
        width: 50%;
      }
    }
    &-pager {
      .bt-box.with-pager {
        padding-top: 68px;
        text-align: center;
      }
    }
  }
}
@media (min-width: $screen-lg-min) {
  .product-detail {
    &-head {
    }
    &-title {
      @include fz(50px);
    }
    &-nav {
      @include fz(18px);
    }
  }
}
@media (min-width: $screen-xl-min) {
  .product-list {
    .item {
      width: 25%;
      &:nth-child(3n+1) {
        clear: none;
      }
      &:nth-child(4n+1) {
        clear: left;
      }
    }
  }
  .product-detail {
    &-spec {
      .col {
        width: percentage(452 / 1284);
      }
      .col3 {
        width: percentage(380 / 1284);
      }
    }
  }
}
@media (min-width: $screen-xxl-min) {

}
