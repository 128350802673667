.table-wrap {
  overflow-x: auto;
}
.table {
  @include table-reset;
  width: 100%;
  line-height: 1.5;
  th, td {
    text-align: left;
    border: none;
  }
}
