.field {
  display: block;
  width: 100%;
  height: $input-height;
  padding: 0 0.5em;
  border: 1px solid #ddd;
  background: #fff;
  font-size: inherit;
  margin-bottom: 0.3em;
  &:focus {
    outline: none;
    border-color: $input-border-focus;
  }
  @include field-placeholder {
    color: $input-color-placeholder;
  }
}
textarea.field {
  height: auto;
  padding: 0.25em 0.5em;
}
.has-error .field {
  border-color: $brand-danger;
}
.has-error,
.has-error .field-label {
  color: $brand-danger;
}
.form-box {
  @include fz(16px);
}
.field-group {
  padding-bottom: 6px;
  line-height: $input-height;
  &.captcha-group {
    .field-box {
      > * {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0.3em;
      }
      .field {
        width: calc(100% - 100px);
      }
      .captcha-img {
        margin-left: 0.3em;
        margin-right: 0.3em;
      }
    }
  }
}
.field-box {
  position: relative;
}
.field-label {
  display: block;
  b {
    color: $brand-primary;
  }
}
.field-inline {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 0.3em;
  margin-right: 0.5em;
  position: relative;
  label {
    &::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin: 0 0.5em 0.1em 0;
      background: url(../img/form_radio.png) no-repeat;
      background-size: 100% auto;
      width: 15px;
      height: 15px;
    }
  }
  input {
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    &:checked + label {
      &::before {
        background-position: 0 100%;
      }
    }
  }
}
.field-set {
  display: table;
  margin-bottom: 0.3em;
  > * {
    display: table-cell;
    vertical-align: middle;
  }
  .field {
    margin: 0;
  }
  .txt {
    padding: 0 1em;
    width: 1%;
  }
}
.hint {
  color: $text-color3;
}
.form-bt-box {
  padding: 1.5em 0 0;
  letter-spacing: 1.5em;
  text-align: center;
  .bt {
    font-size: inherit;
    letter-spacing: $letter-spacing-base;
  }
}

@media (min-width: $screen-xs-min) {
  .field-group {
    &.name-group {
      .field-box {
        padding-right: 9.5em;
      }
      .sex-radio {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .form-bt-box {
    letter-spacing: 2.5em;
  }
}
@media (min-width: $screen-sm-min) {
  .form-box {
    @include fz(18px);
  }
  .field-label {
    float: left;
  }
  .field-box {
    margin-left: 6em;
  }
  .field-group {
    padding-bottom: 12px;
    &.captcha-group {
      .field-box {
        .field {
          width: 177px;
        }
      }
    }
    &.name-group {
      .field-box {
        max-width: 530px;
      }
    }
  }
}
