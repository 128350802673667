
// Body
$body-bg: #fff;
$sect-bg: #f5f5f5;

// Brands
$brand-primary: #0069a0;
$brand-primary2: #feda26;
$brand-primary3: #555555;
$brand-primary4: #c7000a;
$brand-success: #5cb85c;
$brand-info:    #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger:  #d9534f;

$font-family-sans-serif: '微軟正黑體', 'Microsoft JhengHei', Arial, Helvetica, "Helvetica Neue", sans-serif;
$font-family-sans-serif2: Arial, Helvetica, '微軟正黑體', 'Microsoft JhengHei', "Helvetica Neue", sans-serif;
$font-family-sans-serif3: 'Lato', sans-serif;
$font-family-serif: Georgia, 'Times New Roman', Times, '思源宋體', 'source-han-serif-tc', 'PMingLiU', 'SimSun', serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-base: $font-family-sans-serif;
$font-size-base: 14px;
$line-height-base: (24 / 14);
$text-color: #333;
$text-color2: #999;
$text-color3: #666;
$letter-spacing-base: 0;

$border-color: #ccc;
$border-color2: #eee;
$border-color3: #707070;

$input-border: #ddd;
$input-border-focus: $brand-primary;
$input-color-placeholder: #999;
$input-height: 32px;

$screen-xxl: 1680px;
$screen-xl: 1440px;
$screen-lg: 1220px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 480px;

$screen-xxl-min: $screen-xxl;
$screen-xl-min: $screen-xl;
$screen-lg-min: $screen-lg;
$screen-md-min: $screen-md;
$screen-sm-min: $screen-sm;
$screen-xs-min: $screen-xs;

$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);
$screen-lg-max:              ($screen-xl-min - 1);
$screen-xl-max:              ($screen-xxl-min - 1);

$grid-columns: 12;
$grid-gutter-width: 40px;
$container-tablet:             (720px + $grid-gutter-width);
$container-desktop:            (940px + $grid-gutter-width);
$container-large-desktop:      (1140px + $grid-gutter-width);
$container-sm:                 $container-tablet;
$container-md:                 $container-desktop;
$container-lg:                 $container-large-desktop;

$table-cell-padding: 8px;
$table-bg:           transparent !default;
$table-bg-striped:    #f5f5f5 !default;
$table-bg-hover:     #f0f0f0 !default;
$table-bg-active:    $table-bg-hover !default;
$table-border-color: #ddd !default;
