.partner-list {
  margin: 0 -7px;
  @include clearfix;
  .item {
    float: left;
    width: 50%;
    padding: 0 7px;
    &:nth-child(2n+1) {
      clear: left;
    }
  }
  .box {
    text-align: center;
    max-width: 450px;
    margin: 0 auto 2em;
    .pic {
      border: 1px solid $border-color;
      background: rgba(#000,0.05) 50% 50% no-repeat;
      background-size: contain;
      margin-bottom: 1em;
      &:before {
        content: '';
        display: block;
        padding-top: percentage(300 / 450);
      }
    }
    .title {
      line-height: 1.3;
    }
  }
  .box:hover {
    .pic {
      border-color: $brand-primary;
    }
    .title {
      color: $brand-primary;
    }
  }
}


@media (min-width: $screen-xs-min) {

}
@media (min-width: $screen-sm-min) {
  .partner-list {
    margin: 0 -10px;
    .item {
      padding: 0 10px;
      width: 33.33%;
      &:nth-child(2n+1) {
        clear: none;
      }
      &:nth-child(3n+1) {
        clear: left;
      }
    }
    .box {
      @include fz(16px);
      margin-bottom: 60px;
    }
  }
}
@media (min-width: $screen-md-min) {
  .partner-list {
    padding-top: 20px;
    .item {
      width: 25%;
      &:nth-child(3n+1) {
        clear: none;
      }
      &:nth-child(4n+1) {
        clear: left;
      }
    }
    .box {
      margin-bottom: 80px;
    }
  }
}
@media (min-width: $screen-lg-min) {

}
