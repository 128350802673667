* {
  box-sizing: border-box;
  @include selection {
    background: #111;
    color: #fff;
  };
}
*:before,
*:after {
  box-sizing: border-box;
}
body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $body-bg;
  letter-spacing: $letter-spacing-base;
  word-wrap: break-word;
  word-break: normal;
  text-align: justify;
}
a {
  color: inherit;
  text-decoration: none;
  transition: 0.3s;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
}
p {
  margin: 0 0 ($line-height-base + 0em);
}
input,
button,
select,
textarea,
a {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
img {
  display: inline-block;
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  &.has-border {
    border: 1px solid $border-color2;
  }
}
.pic img {
  display: block;
  margin: 0 auto;
}
[role="button"] {
  cursor: pointer;
}
*:focus {
  outline: none;
}
.wrapper {
  overflow: hidden;
}
.container {
  position: relative;
}
.slick-item {
  vertical-align: top;
}
.slick-item + .slick-item {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}
.slick-initialized .slick-item + .slick-item {
  position: relative;
  visibility: visible;
}
.main,
.container,
.clearfix {
  @include clearfix;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0 0 56.25%;
  overflow: hidden;

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    border: 0;
  }
}
.ic {
  display: inline-block;
  vertical-align: text-bottom;
}

@media (min-width: $screen-sm-min) {
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}
