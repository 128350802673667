.wrapper {
}
.container {
  margin: 0 auto;
  padding: 0 20px;
}
.main {
  position: relative;
  .container {
    max-width: 1290px;
  }
  &-top {
    text-align: center;
    background: 50% 50% no-repeat;
    background-size: cover;
    padding: 115px 0 35px;
    color: #fff;
    &-title {
      @include fz(35px);
      font-family: $font-family-sans-serif2;
      font-weight: bold;
    }
  }
  &-content {
    padding: 32px 0 40px;
    &.has-bg {
      background: url(../img/main_bg.png) 50% 100% no-repeat;
      background-size: 100% auto;
    }
  }
  &-title {
    color: $brand-primary;
    font-weight: bold;
    @include fz(18px);
    strong {
      position: relative;
      display: inline-block;
      @include fz(44,18);
      font-family: $font-family-sans-serif3;
      font-weight: 900;
      color: $text-color;
      line-height: 1;
      transform: scale(1.1,1);
      transform-origin: left bottom;
      margin-bottom: 0.1em;
      &:after {
        content: '';
        display: block;
        background: $brand-primary2;
        position: absolute;
        z-index: -1;
        left: -0.2em;
        right: -0.2em;
        bottom: 0;
        height: 20 / 50 + 0em;
      }
    }
    &.c2 {
      color: #fff;
      strong {
        color: $brand-primary2;
        &:after {
          display: none;
        }
      }
    }
  }
}
.content {
  &-title {
    @include fz(24px);
    font-weight: bold;
    line-height: 1.3;
    margin-bottom: 0.2em;
    small {
      color: rgba($text-color,0.5);
    }
    span {
      display: inline-block;
    }
    img {
      display: block;
    }
  }
  &-title2 {
    @include fz(24,16);
    font-weight: bold;
    color: $brand-primary;
    line-height: 1.3;
    margin-bottom: 0.5em;
  }
  &-nav {
    @include fz(16px);
    letter-spacing: 0.8em;
    margin-bottom: 1.25em;
    li {
      letter-spacing: 0;
      a {
        color: #000;
        border: 1px solid $border-color3;
        line-height: 1.3;
        border-radius: 20px;
        padding: 0.25em 1.25em;
        margin-bottom: 0.5em;
      }
    }
    li > a:hover,
    li.current > a {
      color: $brand-primary;
      border-color: $brand-primary;
    }
  }
}
.related {
  background: $border-color2;
  padding: 15px 20px;
  overflow: hidden;
  .link {
    color: $text-color3;
    @include fz(18px);
    .ic {
      margin-bottom: 0.2em;
      margin-left: 0.25em;
    }
    &:hover {
      color: $brand-primary;
    }
  }
  .share {
    float: right;
    .a2a_kit {
      a {
        margin-left: 0.3em;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .main {
    &-top {
      position: relative;
      padding-top: 150px;
      padding-bottom: 80px;
      &:before {
        content: '';
        display: block;
        background: url(../img/banner_deco.png) no-repeat 100% 100%;
        background-size: auto 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        pointer-events: none;
      }
      &-title {
        @include fz(50px);
        line-height: 1.3;
      }
    }
    &-content {
      padding: 66px 0 78px;
    }
    &-title {
      @include fz(20,16);
      strong {
        @include fz(50,20);
      }
    }
  }
  .content {
    &-nav {
      @include fz(20px);
    }
  }
}
@media (min-width: $screen-md-min) {
  .main {
    &-top {
      padding-top: 180px;
      padding-bottom: 128px;
      &-title {
        @include fz(66px);
      }
    }
    &-content {
      &.has-bg {
        padding-bottom: 150px;
      }
    }
  }
  .content {
    &-title {
      img {
        display: inline-block;
        vertical-align: text-bottom;
      }
    }
  }
  .related {
    padding-left: 44px;
    padding-right: 44px;
  }
}
@media (min-width: $screen-lg-min) {
  .main {
    &-top {
      &-title {
        @include fz(80px);
      }
    }
  }
}
@media (min-width: $screen-xl-min) {
  .main {
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  body {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
