.banner {
  position: relative;
  color: #fff;
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(../img/index_banner_deco.png) 50% 50% no-repeat;
    background-size: cover;
    pointer-events: none;
  }
  &-txt {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 75%;
    transform: translate(-50%,-50%);
    text-align: center;
    width: 80%;
    max-width: 280px;
    text-shadow: 0 0 8px rgba(#000,0.5);
    .txt1 {
      letter-spacing: 0.5em;
      @include fz(27.59px);
      font-weight: bold;
      padding-left: 0.5em;
    }
  }
  .bt.scroll {
    position: absolute;
    z-index: 10;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    .ic {
      display: block;
      margin-top: 0.2em;
      font-size: 1.3em;
    }
    &:hover {
      text-shadow: 0 0 10px #000;
    }
  }
}
.index-heading {
  margin-bottom: 30px;
  .num,
  .main-title {
    display: inline-block;
    vertical-align: bottom;
  }
  .num {
    margin-right: 2em;
  }
  .main-title {
    margin: 0;
  }
}
.index-about .pic .num,
.index-heading .num {
  @include txt-hide;
  background-size: contain;
  width: 17 * 7px;
  height: 12 * 7px;
}
.index-about .pic .num {
  background-image: url(../img/index_heading_num_1.png);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(percentage(-52 / 170), percentage(-70 / 120));
}
.index-product .index-heading .num {
  background-image: url(../img/index_heading_num_2.png);
}
.index-case .index-heading .num {
  background-image: url(../img/index_heading_num_3.png);
}
.index-sect {
  padding: 30px 0;
}
.index-about {
  background: url(../img/index_about_bg.png) 50% 50% no-repeat;
  background-size: cover;
  .bt-more {
    font-size: 14px;
    color: $text-color3;
    border-bottom: 1px solid #bbb;
    &:hover {
      color: $brand-primary;
      border-bottom-color: $brand-primary;
    }
  }
}
.index-product {
  background: $brand-primary url(../img/index_product_bg.png) 50% 50% no-repeat;
  background-size: cover;
  color: #fff;
  &-list {
    @include fz(16px);
    text-align: center;
    .slick-list {
      overflow: visible;
    }
    .box {
      span {
        display: block;
      }
      .pic {
        background: 50% 50% no-repeat;
        background-size: contain;
        margin-bottom: 0.6em;
        &:before {
          content: '';
          display: block;
          padding-top: percentage(480 / 900);
        }
      }
      .desc {
        font-size: 0.9em;
        color: rgba(#fff,0.7);
      }
    }
    .box:hover {
      text-decoration: none;
      .title {
        color: $brand-primary2;
      }
    }
  }
  &-control {
    position: relative;
    padding: 15px 0;
    margin: 1.5em auto 0;
    width: 90px;
    .slick-prev,
    .slick-next {
      position: absolute;
      right: 0;
      top: 50%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 2px solid rgba(#fff,0.5);
      margin-top: -20px;
      @include fz(16px);
      .ic {
        vertical-align: baseline;
      }
      &:hover {
        color: $brand-primary2;
        border-color: rgba($brand-primary2,0.5);
      }
    }
    .slick-prev {
      right: 50px;
    }
    .slick-dots {
      @include list-reset;
      display: table;
      width: 100%;
      table-layout: fixed;
      li {
        display: table-cell;
        button {
          @include btn-reset;
          @include txt-hide;
          width: 100%;
          height: 4px;
          background: rgba(#fff,0.3);
        }
      }
      li button:hover,
      li.slick-active button {
        background: #fff;
      }
    }
  }
}
.index-case {
  &-list {
    margin: 0 -7px;
    .item {
      padding: 0 8px;
    }
    .box {
      span {
        display: block;
      }
      .pic {
        border: 1px solid $border-color;
        background: rgba(#000,0.05) 50% 50% no-repeat;
        background-size: contain;
        margin-bottom: 1em;
        &:before {
          content: '';
          display: block;
          padding-top: percentage(300 / 450);
        }
      }
    }
    .box:hover {
      .pic {
        border-color: $brand-primary;
      }
    }
  }
}


@media (min-width: $screen-xs-min) {

}
@media (min-width: $screen-sm-min) {
  .banner {
    &-txt {
      max-width: none;
      .txt1 {
        @include fz(36px);
      }
    }
  }
  .index-sect {
    padding: 60px 0;
  }
  .index-product {
    .index-heading {
      margin-bottom: 50px;
    }
    &-list {
      @include fz(18px);
    }
    &-control {
      margin-top: 3em;
    }
  }
  .index-case {
    .container {
      max-width: 1764px;
    }
    .index-heading {
      text-align: center;
      margin-bottom: 60px;
      .main-title {
        text-align: left;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .banner {
    &-txt {
      .txt1 {
        @include fz(50px);
      }
    }
  }
  .index-about .pic .num,
  .index-heading .num {
    width: 170px;
    height: 120px;
  }
  .index-about {
    padding: 80px 0 90px;
  }
  .index-product {
    .container {
      max-width: 1460px;
    }
    &-control {
      width: 110px;
      max-width: 1250px;
      margin: 64px auto 0;
      .slick-prev,
      .slick-next {
        width: 50px;
        height: 50px;
        @include fz(20px);
        margin-top: -25px;
      }
      .slick-prev {
        right: 60px;
      }
    }
  }
  .index-case {
    padding: 100px 0 80px;
    .index-heading {
      margin-bottom: 90px;
    }
  }
}
@media (min-width: $screen-lg-min) {

}
