.breadcrumb {
  display: inline-block;
  @include list-reset;
  background: rgba($brand-primary,0.5);
  color: #fff;
  min-width: 250 / 18 + 0em;
  padding: 0.2em;
  li {
    display: inline;
    vertical-align: top;
    + li::before {
      content: '/ ';
    }
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        color: $brand-primary2;
        text-decoration: none;
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .breadcrumb {
    @include fz(16px);
  }
}
@media (min-width: $screen-md-min) {
  .breadcrumb {
  }
}
@media (min-width: $screen-lg-min) {
  .breadcrumb {
    @include fz(18px);
  }
}
